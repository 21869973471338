import React, {useEffect} from 'react';
import '../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Extensions/fontawesome/css/all.min.css';
import '../Extensions/fontawesome/webfonts/fa-regular-400.ttf';

const ServiceContent = () => {
  useEffect(() => {
    AOS.init();
  }, [])
    return(
        <section id="services" class="services">
        <div class="container">
            <h3>Unsere Leistungen</h3>
            <hr/>
            <br />
          <div class="serviceRow">

            <div class="serviceBox" data-aos="fade-up">
              <div class="icon-box icon-box-pink">
                <div class="icon"><i class="fas fa-home"></i></div>
                <h4 class="title">Hausservice</h4>
                <p class="description">Alle Arbeiten am Haus, ob Sanierung oder Neubau. Auch bei Entrümpelungen helfen wir gerne!</p>
              </div>
            </div>
    
            <div class="serviceBox" data-aos="fade-up" data-aos-delay="100">
              <div class="icon-box icon-box-cyan">
                <div class="icon"><i class="fas fa-briefcase"></i></div>
                <h4 class="title">Hausmeisterservice</h4>
                <p class="description">Treppen-, Flurreinigung, Kehrwoche, sowie Gebäudereinigung und vieles mehr.</p>
              </div>
            </div>
    
            <div class="serviceBox" data-aos="fade-up" data-aos-delay="200">
              <div class="icon-box icon-box-green">
                <div class="icon"><i class="fas fa-seedling"></i></div>
                <h4 class="title">Gartenservice</h4>
                <p class="description">Vom Rasenmähen bis zum Gartenbau ist alles dabei. Gartenarbeit rund um Euer Heim!</p>
              </div>
            </div>
    
            <div class="serviceBox" data-aos="fade-up" data-aos-delay="300">
              <div class="icon-box icon-box-blue">
                <div class="icon"><i class="far fa-snowflake"></i></div>
                <h4 class="title">Winterdienst</h4>
                <p class="description">Immer an Ort und Stelle, wenn es schneit. Gerne auch zur Vorsorge vor Glatteis stehen wir für Euch zur Verfügung.</p>
              </div>
            </div>
    
          </div>
    
        </div>
      </section>  
    )
}

export default ServiceContent;