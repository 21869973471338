import React from 'react';
import '../App.css';
import {Link} from 'react-router-dom';

import '../Extensions/fontawesome/css/all.min.css';
import '../Extensions/fontawesome/webfonts/fa-regular-400.ttf';

const ImprintContent = () => {
    return(
        <nav>
        <div class="nav-links">
            <Link to="/">Home</Link>
        </div>
    </nav>
    )
}

export default ImprintContent;