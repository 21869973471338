import React, {useEffect} from 'react';
import '../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../Images/gallery/image1.png';
import img2 from '../Images/gallery/image2.jpeg';
import img3 from '../Images/gallery/image3.jpg';
import img4 from '../Images/gallery/image4.jpg';
import img5 from '../Images/gallery/image5.jpg';
import img6 from '../Images/gallery/image6.jpg';
import img7 from '../Images/gallery/image7.jpg';
import img8 from '../Images/gallery/image8.jpeg';
import img9 from '../Images/gallery/image10.jpg';
import img10 from '../Images/gallery/image14.jpg';

import '../Extensions/fontawesome/css/all.min.css';
import '../Extensions/fontawesome/webfonts/fa-regular-400.ttf';

const ImgGalleryContent = () => {
  useEffect(() => {
    AOS.init();
  }, [])
    return(
        <section id="image-gallery" >
          <div class="container">
            <h3>Bildergalerie</h3>
            <hr/>
            <div class="image-gallery">
              <div class="image-gallery-container">
                <img src={img1} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img2} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img3} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div> 
              <div class="image-gallery-container">
                <img src={img4} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img5} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img6} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div> 
              <div class="image-gallery-container">
                <img src={img7} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img8} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
                <img src={img9} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div>   
              <div class="image-gallery-container">
                <img src={img10} class="image-gallery-item" alt="" loading="lazy" data-aos="fade-up"/>
              </div>
            </div>
          </div>
        </section>
    )
}

export default ImgGalleryContent;