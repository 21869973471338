import './App.css';
import HomePage from './pages/index';
import ImprintPage from './pages/imprint';

import {
  BrowserRouter as Router, Routes, Route
} from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Routes>
        <Route path='/' element={<HomePage />} exact />
          <Route path='/impressum' element={<ImprintPage />} exact />
        </Routes>
      </Router>
    </>
  );
}

export default App;
