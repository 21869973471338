import React, {useEffect} from 'react';
import '../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FatosMurati from '../Images/fatos_murati.jpeg'
import '../Extensions/fontawesome/css/all.min.css';
import '../Extensions/fontawesome/webfonts/fa-regular-400.ttf';

const AboutContent = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    return(
        <section id="about">
        <div class="container">

            <h3>Über Uns</h3>
            <hr/>
            <p>
                Wir sind nicht nur Dienstleister für Ihr Haus, sondern auch rund herum. 
                <br/>
                Wir bieten vollste Zufiedenheit aller Kunden und zuverlässige Arbeit. 
                <br/>
                Zudem haben wir ein gutes Preis-Leistungs-Verhältnis. 
                <br/>
                Falls Sie mehr wissen wollen, zögern Sie nicht mich zu kontaktieren.
            </p>

            <div class="team">
                <div class="team-member">
                    <img src={FatosMurati} alt="Fatos Murati" data-aos="fade-up"/>
                    <div class="name">Fatos Murati</div>
                    <div class="role">Gründer & Leiter</div>
                </div>
            </div>

        </div>
    </section>
    )
}

export default AboutContent;