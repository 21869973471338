import React from 'react';
import '../App.css';
import leWebsites from '../Images/leWebsites.png';
import {Link} from 'react-router-dom';

import '../Extensions/fontawesome/css/all.min.css';
import '../Extensions/fontawesome/webfonts/fa-regular-400.ttf';

const FooterContent = () => {
    return(
        <footer>
                <div class="footer-links">
            <Link to="/impressum">Impressum</Link>
        </div>
        <div class="copyright">
            &copy; Copyright <strong><span>Haus-Garten-Services 2023</span></strong>.
        </div>
        <div class="credits">
            <img src={leWebsites} alt="LE Websites" loading='lazy'/>
        </div>
    </footer>
    )
}

export default FooterContent;