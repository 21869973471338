import React, {useEffect} from 'react';
import '../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Extensions/fontawesome/css/all.min.css';
import '../Extensions/fontawesome/webfonts/fa-regular-400.ttf';

const ContactContent = () => {
  useEffect(() => {
    AOS.init();
  }, [])
    return(
        <section id="contact" class="contact">
        <div class="container">
          <h3>Kontakt</h3>
          <hr/>
          <div class="info" data-aos="fade-up">
            <div class="address" data-aos="fade-up" data-aos-delay="100">
              <i class="fas fa-map-marker-alt"></i>
              <h4>Firmensitz:</h4>
              <p>Esslingen am Neckar</p>
            </div>

            <div class="email" data-aos="fade-up" data-aos-delay="100">
              <a href="mailto:f.murati@gmx.ne">
              <i class="far fa-envelope"></i>
                <h4>Email:</h4>
                <p>f.murati@gmx.net</p>
              </a>
            </div>

            <div class="phone" data-aos="fade-up" data-aos-delay="100">
              <i class="fas fa-phone-alt"></i>
              <a href="tel:017649509554">
                <h4>Telefonnummer:</h4>
                <p>+49 176 49 50 95 54</p>
              </a>
            </div>
          </div>
      </div>
  </section>
    )
}

export default ContactContent;