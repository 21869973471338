import React from 'react';

import Header from '../Components/header';
import Services from '../Components/services';
import About from '../Components/about';
import ImgGallery from '../Components/images';
import Contact from '../Components/contact';
import Footer from '../Components/footer';

const HomePage = () => { 

    return(
        
        <> 
           <Header/>
           <Services/>
           <About/>
           <ImgGallery/>
           <Contact/>
           <Footer/>
        </>
    )
};

export default HomePage;