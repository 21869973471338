import React from 'react';
import '../App.css';
import MuratiLogo from '../Images/haus-garten-services-logo.png';
import '../Extensions/fontawesome/css/all.min.css';
import '../Extensions/fontawesome/webfonts/fa-regular-400.ttf';

const HeaderContent = () => {
    return(
        <header>
            <img className='WebsiteLogo' src={MuratiLogo} alt="Haus-garten-services" loading='lazy'/>
            <h1>Fatos Murati</h1>
            <h2>Gärtner Ihres Vertrauens</h2>
            <i class="fa-sharp fa-solid fa-angle-down angleDown"></i>
        </header>
    )
}

export default HeaderContent;