import React from 'react';
import '../App.css';

import '../Extensions/fontawesome/css/all.min.css';
import '../Extensions/fontawesome/webfonts/fa-regular-400.ttf';

const ImprintContent = () => {
    return(
        <section id="imprint">
        <div class="container">
            <h3>Impressum</h3>
            <hr/>
            <div>
                    <p>
                      <p>
                        Angaben gemäß § 5 TMG
                        <br/> <br/>
                        Haus-Garten-Services
                        Esslingen am Neckar
                        <br/>
                        Vertreten durch:
                        <br/>
                        Fatos Murati
                        <br/>
                        Kontakt:
                        <br/>
                        Telefon: +49 (0) 176 49509554<br/>
                        E-Mail: f.murati@gmx.net <br/>
                        <br/>
                        Haftungsausschluss:
                        Haftung für Inhalte
                        <br/>
                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, 
                        Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. 
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. 
                        Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde 
                        Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. 
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. 
                        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. 
                        Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                        <br/>
                        Haftung für Links
                        <br/>
                        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. 
                        Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. 
                        Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. 
                        Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. 
                        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. 
                        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. 
                        Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                        <br/>
                        Dieses Impressum wurde mit dem Impressum Generator der Kanzlei Hasselbach - Spezialanwälte für Trennungsunterhalt - erstellt
                      </p>
                    </p>
            </div>
        </div>
    </section>
    )
}

export default ImprintContent;